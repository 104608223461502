import React from 'react';
import './Fondoproyectos.css';
import { useNavigate } from 'react-router-dom';

const Fondoproyectos = ({ projectsData, activeTab }) => {
  const navigate = useNavigate();

  const filteredProjects = projectsData.filter(project => project.status === activeTab);

  const unifamiliares = filteredProjects.filter(project => project.type === 'Vivienda Unifamiliar');
  const multifamiliares = filteredProjects.filter(project => project.type === 'Vivienda Multifamiliar');
  const comerciales = filteredProjects.filter(project => project.type === 'Comercial');

  const renderProjects = (projects) => (
    projects.map((project, index) => (
      <div className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
        <div className="project-info">
          <div className="content">
            <h3>{project.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: project.description.replace(/\n/g, '<br />') }} />
            {activeTab === 'ejecucion' && (
              <a href={project.pdfUrl} target="_blank" download className="download-btn">Descargar Brochure</a>
            )}
          </div>
        </div>
        <div className="image-container">
          <div className="content">
            <img src={project.imageUrl} alt={project.title} />
          </div>
        </div>
      </div>
    ))
  );

  return (
    <div className="projects-section">
      <h2>CONOCE NUESTROS PROYECTOS</h2>
      <div className="tabs">
        <button
          className={activeTab === 'ejecutadas' ? 'active' : ''}
          onClick={() => navigate('/obras-ejecutadas')}
        >
          Obras Ejecutadas
        </button>
        <button
          className={activeTab === 'ejecucion' ? 'active' : ''}
          onClick={() => navigate('/obras-en-ejecucion')}
        >
          Obras en Ejecución
        </button>
      </div>
      {activeTab === 'ejecutadas' ? (
        <div className="timeline">
          <h3 className="section-title-obras">Viviendas Unifamiliares</h3>
          {renderProjects(unifamiliares)}
          <h3 className="section-title-obras">Viviendas Multifamiliares</h3>
          {renderProjects(multifamiliares)}
          <h3 className="section-title-obras">Proyectos Comerciales</h3>
          {renderProjects(comerciales)}
        </div>
      ) : (
        <div className="timeline">
          <h3 className="section-title-obras">Proyectos 2024</h3>
          {renderProjects(filteredProjects)}
        </div>
      )}
    </div>
  );
};

export default Fondoproyectos;