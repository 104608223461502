import QUEBRADA from '../images/obras_Ejecutadas/2005_CASA_LA_QUEBRADA.jpg';
import CHAMA from '../images/obras_Ejecutadas/2005_MULTIFAMILIAR_CHAMA_SURCO.jpeg';
import PUNTA from '../images/obras_Ejecutadas/2006_CASA_PUNTA_HERMOSA.jpg';
import CERRO from '../images/obras_Ejecutadas/2010_CASA_CERRO_AZUL.jpg';
import CALUSA from '../images/obras_Ejecutadas/2010_MULTIFAMILIAR_RESIDENCIAL_CALUSSA_ATE.jpeg';
import GOLF from '../images/obras_Ejecutadas/2011_CONDOMINIO_GOLF_DE_LA_MOLINA.jpeg';
import MINIKAMAR from '../images/obras_Ejecutadas/2013_CASA_MINIKAMAR_2.jpg';
import CAPULIES from '../images/obras_Ejecutadas/2013_EDIFICIO_MULTIFAMILIAR_CAPULIES_ATE_2.jpeg';
import SOL from '../images/obras_Ejecutadas/2014_CASA_ISLA_DEL_SOL.jpg';
import SAUCOS from '../images/obras_Ejecutadas/2015_EDIFICIO_MULTIFAMILIAR_LOS_SAUCOS_ATE_3.jpg';
import CUPIDO from '../images/obras_Ejecutadas/2017_EDIFICIO_MULTIFAMILIAR_CUPIDO_ATE_6.jpeg';
import PLUTON from '../images/obras_Ejecutadas/2019_EDIFICIO_MULTIFAMILIAR_PLUTON_ATE_2.png';
import VULCANO from '../images/obras_Ejecutadas/2020_INDUSTRIA_Y_OFICINAS_VULCANO_ATE_2.jpeg';
import CAPULIES2024 from '../images/obras_Ejecucion/2024_MULTIFAMILIAR_CAPULIES_SALAMANCA_ATE.png';
import UROS from '../images/obras_Ejecucion/2024_MULTIFAMILIAR_UROS_SALAMANCA_ATE.png';
import ASIA from '../images/obras_Ejecutadas/CASA_ASIA.jpeg'
import CORDOBA from '../images/obras_Ejecutadas/RESIDENCIAL_CORDOBA .png'
import ZAMORA from '../images/obras_Ejecutadas/RESIDENCIAL_ZAMORA.jpeg'


const DatosProyecto = [
  {
    title: 'CASA EN LA QUEBRADA',
    imageUrl: QUEBRADA,
    description: 'Vivienda Unifamiliar\nAREA: 280.00 m2\nPLAYA LA QUEBRADA\nEL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 2 PLANTAS CON VISTAS AL MAR.',
    extraInfo: 'EL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 2 PLANTAS CON VISTAS AL MAR.',
    year: '2005',
    status: 'ejecutadas',
    type: 'Vivienda Unifamiliar'
  },
  {
    title: 'CASA PUNTA HERMOSA',
    imageUrl: PUNTA,
    description: 'Vivienda Unifamiliar\nAREA: 310.00 m2\nPLAYA PUNTA HERMOSA\nEL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 3 PLANTAS.',
    extraInfo: 'EL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 3 PLANTAS.',
    year: '2006',
    status: 'ejecutadas',
    type: 'Vivienda Unifamiliar'
  },
  {
    title: 'CASA CERRO',
    imageUrl: CERRO,
    description: 'Vivienda Unifamiliar\nAREA: 450.00 m2\nPLAYA CERRO AZUL\nEL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 2 PLANTAS CON VISTAS AL MAR.',
    extraInfo: 'EL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 2 PLANTAS CON VISTAS AL MAR.',
    year: '2010',
    status: 'ejecutadas',
    type: 'Vivienda Unifamiliar'
  },
  {
    title: 'CASA BLANCA',
    imageUrl: MINIKAMAR,
    description: 'Vivienda Unifamiliar\nAREA: 450.00 m2\nPLAYA MINKAMAR\nEL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 2 PLANTAS.',
    extraInfo: 'EL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 2 PLANTAS.',
    year: '2013',
    status: 'ejecutadas',
    type: 'Vivienda Unifamiliar'
  },
  {
    title: 'CASA DEL SOL',
    imageUrl: SOL,
    description: 'Vivienda Unifamiliar\nAREA: 300.00 m2\nPLAYA ISLA DEL SOL\nEL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 2 PLANTAS CON VISTAS AL MAR.',
    extraInfo: 'EL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 2 PLANTAS CON VISTAS AL MAR.',
    year: '2014',
    status: 'ejecutadas',
    type: 'Vivienda Unifamiliar'
  },
  {
    title: 'CASA ASIA',
    imageUrl: ASIA,
    description: 'Vivienda Unifamiliar\nAREA: 225.00 m2\nPLAYA DE ASIA\nEL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 3 PLANTAS.',
    extraInfo: 'EL PROYECTO SE COMPONE EN UNA CASA DE PLAYA DISTRIBUIDA EN 3 PLANTAS.',
    year: '2019',
    status: 'ejecutadas',
    type: 'Vivienda Unifamiliar'
  },
  {
    title: 'EDIFICIO CHAMA',
    imageUrl: CHAMA,
    description: 'Vivienda Multifamiliar\nAREA: 800.00 m2\nSANTIAGO DE SURCO\nEL PROYECTO SE COMPONE DE UN EDIFICIO BIFAMILIAR DE 4 PISOS.',
    extraInfo: 'EL PROYECTO SE COMPONE DE UN EDIFICIO BIFAMILIAR DE 4 PISOS.',
    year: '2005',
    status: 'ejecutadas',
    type: 'Vivienda Multifamiliar'
  },
  {
    title: 'RESIDENCIAL CALUSA',
    imageUrl: CALUSA,
    description: 'Vivienda Multifamiliar\nAREA: 900.00 m2\nATE\nEL PROYECTO SE COMPONE DE UN EDIFICIO MULTIFAMILIAR DE 3 PISOS CON AZOTEA.',
    extraInfo: 'EL PROYECTO SE COMPONE DE UN EDIFICIO MULTIFAMILIAR DE 3 PISOS CON AZOTEA.',
    year: '2010',
    status: 'ejecutadas',
    type: 'Vivienda Multifamiliar'
  },
  {
    title: 'COND. GOLF DE LA MOLINA',
    imageUrl: GOLF,
    description: 'Vivienda Multifamiliar\nAREA: 1,464.68 m2\nLA MOLINA\nEL PROYECTO SE COMPONE UNA QUINTA CON CASAS DE 2 PISOS Y UN SOTANO CONJUNTO.',
    extraInfo: 'EL PROYECTO SE COMPONE UNA QUINTA CON CASAS DE 2 PISOS Y UN SOTANO CONJUNTO.',
    year: '2011',
    status: 'ejecutadas',
    type: 'Vivienda Multifamiliar'
  },
  {
    title: 'RESIDENCIAL LOS CAPULIES',
    imageUrl: CAPULIES,
    description: 'Vivienda Multifamiliar\nAREA: 370.92 m2\nATE\nEL PROYECTO SE COMPONE DE UN MULTIFAMILIAR DE 4 PISOS MAS AZOTEA.',
    extraInfo: 'EL PROYECTO SE COMPONE DE UN MULTIFAMILIAR DE 4 PISOS MAS AZOTEA.',
    year: '2013',
    status: 'ejecutadas',
    type: 'Vivienda Multifamiliar'
  },
  {
    title: 'RESIDENCIAL LOS SAUCOS',
    imageUrl: SAUCOS,
    description: 'Vivienda Multifamiliar\nAREA: 1784.59 m2\nATE\nEL PROYECTO SE COMPONE DE UN EDIFICIO MULTIFAMILIAR DE 7 PISOS, AZOTEA Y SEMISOTANO.',
    extraInfo: 'EL PROYECTO SE COMPONE DE UN EDIFICIO MULTIFAMILIAR DE 7 PISOS, AZOTEA Y SEMISOTANO.',
    year: '2015',
    status: 'ejecutadas',
    type: 'Vivienda Multifamiliar'
  },
  {
    title: 'RESIDENCIAL CUPIDO',
    imageUrl: CUPIDO,
    description: 'Vivienda Multifamiliar\nAREA: 1364.69 m2\nATE\nEL PROYECTO SE COMPONE DE UN EDIFICIO MULTIFAMILIAR DE 6 PISOS CON SEMISOTANO.',
    extraInfo: 'EL PROYECTO SE COMPONE DE UN EDIFICIO MULTIFAMILIAR DE 6 PISOS CON SEMISOTANO.',
    year: '2017',
    status: 'ejecutadas',
    type: 'Vivienda Multifamiliar'
  },
  {
    title: 'RESIDENCIAL PLUTON',
    imageUrl: PLUTON,
    description: 'Vivienda Multifamiliar\nAREA: 800.00 m2\nATE\nEL PROYECTO SE COMPONE EN UN EDIFICIO MULTIFAMILIAR DE 5 PISOS CON AZOTEA Y SEMISOTANO.',
    extraInfo: 'EL PROYECTO SE COMPONE EN UN EDIFICIO MULTIFAMILIAR DE 5 PISOS CON AZOTEA Y SEMISOTANO.',
    year: '2020',
    status: 'ejecutadas',
    type: 'Vivienda Multifamiliar'
  },
  {
    title: 'EDIFICIO VULCANO',
    imageUrl: VULCANO,
    description: 'Oficina - Industria\nAREA: 1292.59 m2\nATE\nEL PROYECTO FUE DESARROLLADO A RAIZ DE UNA AMPLIACION Y REMODELACION DE OFICINAS E INDUSTRIA.',
    extraInfo: 'EL PROYECTO FUE DESARROLLADO A RAIZ DE UNA AMPLIACION Y REMODELACION DE OFICINAS E INDUSTRIA.',
    year: '2020',
    status: 'ejecutadas',
    type: 'Comercial'
  },
  {
    title: 'EDIFICIO CAPULIES',
    imageUrl: CAPULIES2024,
    description: 'Capulíes es un edificio multifamiliar de 9 pisos más azotea, con 24 departamentos modernos de 2 y 3 dormitorios desde 67.5 m².\n\nCada departamento tiene vista a la calle gracias a sus dos frentes. El edificio cuenta con ascensor de última generación, sistema contra incendios, cámaras de seguridad y áreas comunes como sala de usos múltiples, gimnasio, parqueo de bicicletas y terrazas con parrilla.\n\nUbicado en Jr Los Capulíes 281, Urb. Recaudadores, Salamanca.',
    extraInfo: 'Capulíes es un edificio multifamiliar de 9 pisos más azotea, con 24 departamentos modernos de 2 y 3 dormitorios desde 67.5 m².\n\nCada departamento tiene vista a la calle gracias a sus dos frentes. El edificio cuenta con ascensor de última generación, sistema contra incendios, cámaras de seguridad y áreas comunes como sala de usos múltiples, gimnasio, parqueo de bicicletas y terrazas con parrilla.\n\nUbicado en Jr Los Capulíes 281, Urb. Recaudadores, Salamanca.',
    year: '2024',
    status: 'ejecucion',
    type: 'Vivienda Multifamiliar',
    pdfUrl: 'https://documents.moviher.com/capulies_brochure.pdf'
  },
  {
    title: 'EDIFICIO UROS',
    imageUrl: UROS,
    description: 'Uros es un edificio multifamiliar de 4 pisos, con 4 departamentos modernos de 3 dormitorios desde 90.70 m².\n\nCada departamento tiene vista al Parque Fitzcarral, con balcones y parqueo de bicicletas. Los departamentos están bien iluminados y ventilados.\n\nUbicado en el corazón de Salamanca, cerca del Jockey Plaza, centros empresariales, colegios, universidades, restaurantes y centros comerciales importantes. Dirección: Calle Los Uros 276, Urb. Recaudadores, Salamanca.',
    extraInfo: 'Uros es un edificio multifamiliar de 4 pisos, con 4 departamentos modernos de 3 dormitorios desde 90.70 m².\n\nCada departamento tiene vista al Parque Fitzcarral, con balcones y parqueo de bicicletas. Los departamentos están bien iluminados y ventilados.\n\nUbicado en el corazón de Salamanca, cerca del Jockey Plaza, centros empresariales, colegios, universidades, restaurantes y centros comerciales importantes. Dirección: Calle Los Uros 276, Urb. Recaudadores, Salamanca.',
    year: '2024',
    status: 'ejecucion',
    type: 'Vivienda Multifamiliar',
    pdfUrl: 'https://documents.moviher.com/uros_brochure.pdf'
  },
  {
    title: 'RESIDENCIAL CORDOVA',
    imageUrl: CORDOBA,
    description: 'Vivienda Multifamiliar\nAREA: 2,800.00 m2\nLINCE\nEL PROYECTO SE COMPONE DE UN EDIFICIO MULTIFAMILIAR DE 8 PISOS CON UN SOTANO.',
    extraInfo: 'EL PROYECTO SE COMPONE DE UN EDIFICIO MULTIFAMILIAR DE 8 PISOS CON UN SOTANO.',
    year: '2008',
    type: 'Vivienda Multifamiliar',
    status: 'ejecutadas'
  },
  {
    title: 'RESIDENCIAL ZAMORA',
    imageUrl: ZAMORA,
    description: 'Vivienda Multifamiliar\nAREA: 1,950.00 m2\nSANTIAGO DE SURCO\nEL PROYECTO SE COMPONE DE UN EDIFICIO MULTIFAMILIAR DE 5 PISOS CON SEMISOTANO.',
    extraInfo: 'EL PROYECTO SE COMPONE DE UN EDIFICIO MULTIFAMILIAR DE 5 PISOS CON SEMISOTANO.',
    year: '2013',
    type: 'Vivienda Multifamiliar',
    status: 'ejecutadas'
  }
];

export default DatosProyecto;